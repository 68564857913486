<template>
  <register-container>
    <template slot="header">{{ pageTitle || $t('registration.registerSurvey.title') }}</template>
    <template slot="subtitle">{{ pageSubtitle }} </template>
    <div
      class="content-wrap d-flex justify-center mt-10"
      :class="$vuetify.breakpoint.smAndDown ? 'align-center' : 'align-start'"
      :style="{ minHeight }"
    >
      <survey-form
        v-if="!surveyCompleted && schema"
        :schema="schema"
        :templateId="formId"
        @newSubmission="onSubmit"
        :onSurveyCreate="onSurveyCreate"
        :customSubmit="customSubmit"
      ></survey-form>
      <div v-else-if="surveyCompleted && !noTemplate" class="d-flex flex-column">
        <v-icon size="100">mdi-checkbox-marked-outline</v-icon>
        <div class="text-h4 mt-2">{{ $t('registration.registerSurvey.complete') }}</div>
      </div>
      <div v-else-if="surveyCompleted && noTemplate" class="d-flex flex-column">
        <v-icon size="100">mdi-checkbox-marked-outline</v-icon>
        <div class="text-h5 mt-2">
          {{ $t('registration.registerSurvey.noData') }}
        </div>
      </div>
      <div v-else><v-progress-circular indeterminate></v-progress-circular></div>
    </div>
  </register-container>
</template>
<style scoped>
.content-wrap {
  min-height: 600px;
}
</style>
<script>
import to from 'await-to-js';
import RegisterContainer from '../components/RegisterContainer.vue';
import SurveyForm from '../components/SurveyForm.vue';
import f from '../utils/fetch';

export default {
  name: 'RegisterSurvey',
  data() {
    return {
      customSubmit: null,
      formId: null,
      noTemplate: false,
      surveyCompleted: false,
      template: null,
      schema: null,
      model: null,
      templateId: null,
      showEndSplash: true,
      survey: null,
    };
  },
  components: { RegisterContainer, SurveyForm },
  computed: {
    completed() {
      return this.surveyCompleted;
    },
    form() {
      return this.$route.query.form || this.$store.state.registration.form;
    },
    minHeight() {
      return this.$vuetify.breakpoint.smAndDown ? `300px` : '600px';
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
  },
  methods: {
    getSurvey() {
      this.templateId = this.form;
    },
    onSubmit() {
      this.surveyCompleted = true;

      if (this.showEndSplash) {
        this.$emit('changeOptions', { showFooter: true });
      } else {
        this.$emit('next', { clearHistoryItem: this.$options.name });
      }
    },
    async loadTemplates(templateId) {
      if (!templateId) {
        this.noTemplate = true;
        this.onSubmit();
        return;
      }
      const providerId = this.$store.getters.getProviderId();
      this.loading = true;

      const [error, template] = await to(
        f(`providers/${providerId}/publicTemplates/${templateId}`)
      );

      if (!error) {
        this.template = template;
        this.schema = template.schema;
        this.formId = template.id;
      }

      if (!this.schema) {
        this.noTemplate = true;
        this.onSubmit();
      }

      this.loading = false;
    },
    setSurvey(val) {
      this.survey = val;
    },
  },
  async mounted() {
    this.getSurvey();
    this.$store.commit('setCompleted', this.completed);
    this.$store.commit('setOnNextClick', () => {
      this.$store.commit('setCompleted', false);
      if (!this.isPageflowV2) {
        this.$router.push({
          name: this.$store.getters.nextFlowRoute(this.$route.name),
        });
      } else {
        this.$emit('next');
      }
    });
    this.initVariables = { survey: this.survey };
    this.$nextTick(() => {
      this.loadTemplates(this.form);
    });
  },
  watch: {
    completed(val) {
      this.$store.commit('setCompleted', val);
    },
  },
};
</script>
